import DropButton from '~/components/interactive/DropButton';
import InputDate, { InputDateProps } from '~/components/input/InputDate';
import { useTz } from '~/components/hooks/useTz';
import IconCalendar from '~/components/icons/streamline/line/IconCalendar';

export default function InputDay(props: Omit<Extract<InputDateProps, { required: true }>, 'required'>) {
  const tz = useTz();

  return (
    <DropButton icon={<IconCalendar />} text={props.value ? tz(props.value).toFormat('MM-dd-yyyy') : 'Select Day'}>
      <div className="p-2">
        <InputDate required {...props} />
      </div>
    </DropButton>
  );
}
